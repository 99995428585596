var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-3",attrs:{"flat":""}},[_c('v-card-title',[_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"hide-details":"","single-line":"","placeholder":"Busca","append-icon":"search","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',{staticClass:"mt-n6",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('headers-data-table',{attrs:{"headers":_vm.headers,"default-headers":_vm.getDefaultHeadersOptions(),"headers-settings-name":"headers-list-units-retailers"},on:{"update:headers":function($event){_vm.headers=$event}}})],1)],1),_c('v-data-table',{staticClass:"clickable-table",attrs:{"fixed-header":"","loading":_vm.$apollo.loading,"footer-props":{ itemsPerPageOptions: [5, 10, 25, 50] },"options":_vm.options,"headers":_vm.computedHeaders,"items":_vm.accountsDataItems,"item-class":"clickable-row"},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.goToAccount},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":_vm.getAccountStatus(item.status_account).color}},[_vm._v("mdi-brightness-1")]),_vm._v(_vm._s(_vm.getAccountStatus(item.status_account).label)+" ")]}},{key:"item.id_external",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id_external)+" ")]}},{key:"item.db_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.db_name)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.erp.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.erp.name; }))+" ")]}},{key:"item.status_reporter.last_dag_completion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.status_reporter.last_dag_completion; }))+" ")]}},{key:"item.status_reporter.max_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(_vm.getSafe(function () { return item.status_reporter.max_date; }),'dd/MM/yyyy'))+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }