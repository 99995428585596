<template>
  <v-card class="mt-3" flat>
    <v-card-title>
      <v-text-field v-model="search" class="pt-0 mt-0" hide-details single-line="" placeholder="Busca" append-icon="search" outlined />
      <v-spacer />
    </v-card-title>
    <v-card-text>
      <v-row justify="end" class="mt-n6">
        <v-col cols="auto">
          <headers-data-table :headers.sync="headers" :default-headers="getDefaultHeadersOptions()" headers-settings-name="headers-list-units-retailers" />
        </v-col>
      </v-row>
      <v-data-table
        fixed-header
        class="clickable-table"
        :loading="$apollo.loading"
        :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
        :options.sync="options"
        :headers="computedHeaders"
        :items="accountsDataItems"
        item-class="clickable-row"
        @click:row="goToAccount"
      >
        <template v-slot:[`item.status`]="{ item }">
          <v-icon class="mr-2" x-small :color="getAccountStatus(item.status_account).color">mdi-brightness-1</v-icon
          >{{ getAccountStatus(item.status_account).label }}
        </template>
        <template v-slot:[`item.id_external`]="{ item }">
          {{ item.id_external }}
        </template>
        <template v-slot:[`item.db_name`]="{ item }">
          {{ item.db_name }}
        </template>
        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:[`item.erp.name`]="{ item }">
          {{ getSafe(() => item.erp.name) }}
        </template>
        <template v-slot:[`item.status_reporter.last_dag_completion`]="{ item }">
          {{ getSafe(() => item.status_reporter.last_dag_completion) }}
        </template>
        <template v-slot:[`item.status_reporter.max_date`]="{ item }">
          {{ getSafe(() => item.status_reporter.max_date) | date('dd/MM/yyyy') }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { QUERY_GET_ACCOUNTS } from '@/modules/accounts/graphql/edit/general.js'
import { headers } from '@/mixin'
import { cloneDeep } from 'lodash'
import { diacriticSpaceSensitiveRegex } from '@/helpers/search.js'
import { getAccountStatus } from '@/constants'

export default {
  components: {
    HeadersDataTable: () => import('@/components/atomic-components/molecules/HeadersDataTable')
  },
  mixins: [headers],

  data: () => ({
    search: '',
    options: {},
    accountsData: [],
    headers: []
  }),
  computed: {
    unitStatus() {
      return [
        { label: 'Cadastrada na account', value: 'REGISTERED', color: '#07DD18', active: true },
        { label: 'Configurada', value: 'CONFIGURED', color: '#EDB900', active: true },
        { label: 'Pendente de configuração', value: 'PENDING_CONFIGURATION', color: '#FF5252', active: true }
      ]
    },
    accountsDataItems() {
      let filteredAccounts = this.accountsData
      if (this.search) {
        filteredAccounts = filteredAccounts.filter(item => {
          const digitSearch = this.search.replace(/\D/g, '')
          const digitSearchRegex = new RegExp(digitSearch)
          const searchRegex = diacriticSpaceSensitiveRegex(this.search)

          return (digitSearch && digitSearchRegex.test(item.id_external.replace(/\D/g, ''))) || searchRegex.test(item.name) || searchRegex.test(item.db_name)
        })
      }
      return filteredAccounts
    },
    tableHeaders() {
      return [
        {
          text: 'Status',
          value: 'status',
          align: 'left',
          width: '10%',
          isActive: true
        },
        {
          text: 'ID Varejista',
          value: 'id_external',
          width: '5%',
          isActive: true
        },
        {
          text: 'DB Name',
          value: 'db_name',
          width: '15%',
          isActive: true
        },
        {
          text: 'ERP/PDV',
          value: 'erp.name',
          width: '10%',
          isActive: true
        },
        {
          text: 'Data de última DAG',
          value: 'status_reporter.last_dag_completion',
          width: '10%',
          isActive: true
        },
        {
          text: 'Data da última venda',
          value: 'status_reporter.max_date',
          width: '10%',
          isActive: true
        }
      ]
    }
  },
  apollo: {
    accounts: {
      query: QUERY_GET_ACCOUNTS,
      fetchPolicy: 'network-only',
      variables() {
        return {
          client_id: this.$route.params.id
        }
      },
      update({ adminAccounts }) {
        this.accountsData = adminAccounts
      }
    }
  },
  mounted() {
    if (this.headers.length < 1) this.headers = cloneDeep(this.tableHeaders)
  },
  methods: {
    getAccountStatus,
    getDefaultHeadersOptions() {
      return cloneDeep(this.tableHeaders)
    },
    goToAccount(evt) {
      const url = `/#/accounts/${evt._id}/edit/geral`
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="css">
.clickable-table td {
  cursor: pointer;
}
</style>
